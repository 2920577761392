/* eslint-disable */
import React from "react";
import { Divider, Button, Modal, Row, Col, Checkbox } from "antd";
import { Link, withRouter } from "react-router-dom";
import "./css/History.css";
import axios from "axios";
import QRCode from "qrcode.react";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
// import {history}
class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isModalVisibleCancelQ: false,
      checkBoxCancelQ: false,
      dataCancelQBooDatetime: "",
      dataCancelQBooCode: "",
      dataCancelQSiteName: "",
      dataCancelQTywName: "",
      username: this.props.username,
      language: this.props.language,
      // username: "d14be89f564cb52df5d5300c494af393ee493e87",
      // language: 1,
    };
  }
  showModalCancelQ(boo_datetime, boo_code, site_name, tyw_name) {
    this.setState({
      isModalVisibleCancelQ: true,
      dataCancelQBooDatetime: boo_datetime,
      dataCancelQBooCode: boo_code,
      dataCancelQSiteName: site_name,
      dataCancelQTywName: tyw_name,
    });
  }
  hideModalCancelQ() {
    this.setState({
      isModalVisibleCancelQ: false,
    });
  }
  onChangecheckBoxCancelQ(e) {
    this.setState({
      checkBoxCancelQ: e.target.checked,
    });
  }
  onCancelQ() {
    const data = {
      username: this.state.username,
      timestamp: this.state.dataCancelQBooDatetime,
      boo_code: this.state.dataCancelQBooCode,
    };
    try {
      axios
        .post(process.env.REACT_APP_API_DLT1 + "/uWeb/cancelBooking", data)
        .then((response) => {
          if (response.data == "cancel booking by uWeb complete") {
            this.hideModalCancelQ();
            this.componentDidMount();
            this.props.history.push("/dlt-Smartqueue/historycancel");
          }
        });
    } catch (e) {}
  }
  componentDidMount() {
    try {
      axios
        .get(process.env.REACT_APP_API_DLT1 + "/getBooking", {
          params: {
            username: this.state.username,
            boo_status: 1,
            lang: this.state.language,
          },
        })
        .then((response) => {
          if (response.data != "can't find booking of this username") {
            this.setState({
              data: response.data,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  // convert time th to en
  setBooround = (boo_round) => {
    const roundAll = boo_round.split(" ");
    const roundStart = roundAll[0];
    const roundEnd = roundAll[2];
    if (parseInt(roundStart.slice(0, 2)) > 12) {
      const roundStartnew = parseInt(roundStart.slice(0, 2)) - 12;
      const roundEndnew = parseInt(roundEnd.slice(0, 2)) - 12;
      const last = "PM";
      return `${roundStartnew.toString() + roundStart.slice(2)} - ${
        roundEndnew.toString() + roundEnd.slice(2)
      } ${last}`;
    } else {
      const last = "AM";
      return `${roundStart} - ${roundEnd} ${last}`;
    }
  };
  render() {
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 0
    ) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="content-history">
        <Row justify="center" align="middle" className="container-history-main">
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            xl={{ span: 20 }}
            className="container-history-main-bg"
          >
            {this.state.data.map((dataProgress, index) => {
              return (
                <div key={index}>
                  <Row justify="center" className="row-header-history">
                    {/* header */}
                    <Col
                      className="col-header-history"
                      xs={{ span: 22 }}
                      sm={{ span: 22 }}
                      xl={{ span: 20 }}
                    >
                      <Col offset={1}>
                        <h1 className="title-header-history">
                          {this.state.language == 1
                            ? "ข้อมูลการจองคิว"
                            : "Booking information"}
                        </h1>
                      </Col>
                    </Col>
                    {/* content */}
                    <Col
                      className="col-content-history"
                      xs={{ span: 22 }}
                      sm={{ span: 22 }}
                      xl={{ span: 20 }}
                    >
                      <Row>
                        {/* detail */}
                        <Col
                          className="col-content-history-detail"
                          xs={{ span: 22, offset: 1 }}
                          sm={{ span: 16, offset: 1 }}
                          xl={{ span: 18, offset: 1 }}
                        >
                          {/* name and passport */}
                          <Row>
                            <Col
                              xs={{ span: 20 }}
                              sm={{ span: 11 }}
                              xl={{ span: 11 }}
                              className="col-content-history-detail-name"
                            >
                              <p className="p-topic">
                                {this.state.language == 1
                                  ? "ชื่อ - นามสกุล"
                                  : "Name"}
                              </p>
                              <p className="p-value">
                                {dataProgress.per_fname}{" "}
                                {dataProgress.per_lname}
                              </p>
                            </Col>
                            <Col
                              xs={{ span: 20 }}
                              sm={{ span: 11 }}
                              xl={{ span: 11 }}
                              className="col-content-history-detail-passport"
                            >
                              <p className="p-topic">
                                {this.state.language == 1
                                  ? "เลขประจำตัวประชาชน"
                                  : "ID No."}
                              </p>
                              <p className="p-value">{dataProgress.per_id}</p>
                            </Col>
                          </Row>
                          <Divider style={{ marginTop: 0, marginBottom: 14 }} />
                          {/* date and time */}
                          <Row>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 12 }}
                              xl={{ span: 12 }}
                              className="col-content-history-detail-date"
                            >
                              <p className="p-topic">
                                {this.state.language == 1
                                  ? "วันที่"
                                  : "Booking date"}
                              </p>
                              <p className="p-value">{dataProgress.dateText}</p>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 12 }}
                              xl={{ span: 12 }}
                              className="col-content-history-detail-time"
                            >
                              <p className="p-topic">
                                {this.state.language == 1 ? "เวลา" : "Round"}
                              </p>
                              <p className="p-value">
                                {this.state.language == 1
                                  ? dataProgress.boo_round
                                  : this.setBooround(dataProgress.boo_round)}
                              </p>
                            </Col>
                          </Row>
                          {/* tyw */}
                          <Row>
                            <Col
                              xs={{ span: 20 }}
                              sm={{ span: 22 }}
                              xl={{ span: 22 }}
                              className="col-content-history-detail-tyw"
                            >
                              {/* <Row> */}
                              <p className="p-topic">
                                {this.state.language == 1
                                  ? "ประเภทงานบริการ"
                                  : "Type"}
                              </p>
                              <p className="p-value">{dataProgress.tyw_name}</p>
                              {/* </Row> */}
                            </Col>
                          </Row>
                          {/* site */}
                          <Row>
                            <Col
                              xs={{ span: 20 }}
                              sm={{ span: 22 }}
                              xl={{ span: 22 }}
                              className="col-content-history-detail-site"
                            >
                              <p className="p-topic">
                                {this.state.language == 1
                                  ? "สำนักงาน"
                                  : "Office"}
                              </p>
                              <p className="p-value">{dataProgress.sit_name}</p>
                            </Col>
                          </Row>
                          {/* หมดอายุเกิน 1 ปี */}
                          {dataProgress.threeyear ? (
                            <Row>
                              <Col
                                xs={{ span: 20 }}
                                sm={{ span: 22 }}
                                xl={{ span: 22 }}
                                className="col-content-history-detail-site"
                              >
                                <p className="p-topic">
                                  {this.state.language == 1
                                    ? "หมายเหตุ"
                                    : "Warning"}
                                </p>
                                <p className="p-value">
                                  {this.state.language == 1
                                    ? "ใบอนุญาตขับรถของท่านสิ้นอายุเกิน 3 ปี"
                                    : "your driver's license has expired more than 3 year."}
                                </p>
                              </Col>
                            </Row>
                          ) : dataProgress.one_year ? (
                            <Row>
                              <Col
                                xs={{ span: 20 }}
                                sm={{ span: 22 }}
                                xl={{ span: 22 }}
                                className="col-content-history-detail-site"
                              >
                                <p className="p-topic">
                                  {this.state.language == 1
                                    ? "หมายเหตุ"
                                    : "Warning"}
                                </p>
                                <p className="p-value">
                                  {this.state.language == 1
                                    ? "ใบอนุญาตขับรถของท่านสิ้นอายุเกิน 1 ปี"
                                    : "your driver's license has expired more than 1 year."}
                                </p>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                        {/* QRcode */}
                        <Col
                          className="col-content-history-qrcode"
                          xs={{ span: 23 }}
                          sm={{ span: 6 }}
                          xl={{ span: 4 }}
                        >
                          <Row>
                            <Col
                              xs={{ span: 20, offset: 2 }}
                              sm={{ span: 20, offset: 2 }}
                              xl={{ span: 20, offset: 2 }}
                              className="col-content-history-detail-qrcode"
                            >
                              <p className="p-topic">
                                {this.state.language == 1
                                  ? "สแกนข้อมูลการจองคิว"
                                  : "Scan booking information."}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={{ span: 11, offset: 7 }}
                              sm={{ span: 22, offset: 1 }}
                              xl={{ span: 22, offset: 1 }}
                              className="col-content-history-detail-qrcode-img"
                            >
                              <QRCode
                                className="QRcode"
                                value={dataProgress.ticketurl}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={{ span: 20, offset: 2 }}
                              sm={{ span: 22, offset: 1 }}
                              xl={{ span: 22, offset: 1 }}
                              className="col-content-history-detail-qrcode"
                            >
                              <p className="p-topic">{dataProgress.boo_code}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={{ span: 20, offset: 2 }}
                              sm={{ span: 22, offset: 1 }}
                              xl={{ span: 22, offset: 1 }}
                              className="col-content-history-detail-qrcode"
                            >
                              <p className="p-topic">QRCode</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* button */}
                  <Row>
                    <Col
                      className="col-button-history button-mar"
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 8, offset: 4 }}
                      xl={{ span: 5, offset: 7 }}
                    >
                      <Link
                        to={{
                          pathname: "/dlt-Smartqueue/documentdetail",
                          state: {
                            language: this.state.language,
                            sit_name: dataProgress.sit_name,
                            tyg_name: dataProgress.tyg_name,
                            tyw_name: dataProgress.tyw_name,
                            tyw_comment: dataProgress.tyw_comment,
                          },
                        }}
                      >
                        <Button className="button-history" size="large">
                          <p>
                            {this.state.language == 1
                              ? "รายละเอียดเอกสารที่ต้องเตรียม"
                              : "Required Documents"}
                          </p>
                        </Button>
                      </Link>
                    </Col>
                    <Col
                      className="col-button-history-cancle button-mar"
                      xs={{ span: 20, offset: 2 }}
                      sm={{ span: 8, offset: 0 }}
                      xl={{ span: 5, offset: 0 }}
                    >
                      <Button
                        className="button-history-cancel"
                        size="large"
                        onClick={() =>
                          this.showModalCancelQ(
                            dataProgress.boo_datetime,
                            dataProgress.boo_code,
                            dataProgress.sit_name,
                            dataProgress.tyw_name
                          )
                        }
                      >
                        <p>
                          {this.state.language == 1 ? "ยกเลิกคิว" : "Cancel"}
                        </p>
                      </Button>
                    </Col>
                    <Divider />
                  </Row>
                </div>
              );
            })}
            <Modal
              className="history-modalCancelQ"
              visible={this.state.isModalVisibleCancelQ}
              closable={false}
              footer={null}
            >
              <Row>
                <Col
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 20, offset: 2 }}
                  xl={{ span: 20, offset: 2 }}
                  className="col-history-modalCancelQ-text-top"
                >
                  <h1 className="text-history-modalCancelQ-modal">
                    {this.state.language == 1
                      ? "ท่านต้องการยกเลิกคิว"
                      : "You want to cancel the booking"}
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 20, offset: 2 }}
                  xl={{ span: 20, offset: 2 }}
                  className="col-history-modalCancelQ-text-top"
                >
                  <h2 className="text-history-modalCancelQ-modal">
                    {this.state.dataCancelQSiteName}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 20, offset: 2 }}
                  xl={{ span: 20, offset: 2 }}
                  className="col-history-modalCancelQ-text-sup"
                >
                  <h2 className="text-history-modalCancelQ-modal">
                    {this.state.dataCancelQTywName}
                  </h2>
                </Col>
              </Row>
              {/* checkbox */}
              <Row className="row-history-modalCancelQ-checkbox">
                <Col
                  className="col-history-modalCancelQ-input"
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 20, offset: 2 }}
                  xl={{ span: 20, offset: 2 }}
                >
                  <Row className="row-history-modalCancelQ-detail-checkbox">
                    <Checkbox
                      className="checkbox-history-modalCancelQ"
                      onChange={(e) => this.onChangecheckBoxCancelQ(e)}
                    ></Checkbox>
                    <h4 className="text-history-modalCancelQ-checkbox">
                      {this.state.language == 1
                        ? "ยืนยันการดำเนินการยกเลิกการจองคิว"
                        : "I agree to cancel the booking"}
                    </h4>
                  </Row>
                </Col>
              </Row>
              {/* button */}
              <Row>
                <Col
                  className="col-history-modalCancelQ-button "
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 8, offset: 4 }}
                  xl={{ span: 8, offset: 4 }}
                >
                  <Button
                    className="button-history-modalCancelQ"
                    onClick={() => this.hideModalCancelQ()}
                  >
                    <p className="p-button-history-modalCancelQ">
                      {this.state.language == 1 ? "ยกเลิก" : "Cancel"}
                    </p>
                  </Button>
                </Col>
                <Col
                  className="col-history-modalCancelQ-button"
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 10, offset: 0 }}
                  xl={{ span: 10, offset: 0 }}
                >
                  {/* <Link to="/dlt-Smartqueue/historycancel"> */}
                  <Button
                    disabled={
                      this.state.checkBoxCancelQ == false ? true : false
                    }
                    className="button-history-modalCancelQ"
                    onClick={() => this.onCancelQ()}
                  >
                    <p className="p-button-history-modalCancelQ">
                      {this.state.language == 1 ? "รับทราบ" : "Confirm"}
                    </p>
                  </Button>
                  {/* </Link> */}
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(History);
