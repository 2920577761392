/* eslint-disable */
import React from "react";
import { Button, Row, Col, Steps, Grid } from "antd";
import "../../../css/Home/Home.css";
import Office from "../Components/Home/Office.component";
import ServiceType from "../Components/Home/ServiceType.component";
import Typework from "../Components/Home/Typework.component";
import Calendar from "../Components/Home/Calendar.component";
import ConfirmQueue from "../Components/Home/ConfirmQueue.component";
import Information from "../Components/Home/Information.component";
import LicenseType from "../Components/Home/LicenseType.component";
import Service from "../Components/Home/Service.component";
import VehicleType from "../Components/Home/VehicleType.component";
import InfoQueue from "../Components/Home/InfoQueue.component";
import Logo from "../../../Icon/logo-web.svg";
import Detail from "../Components/Home/Detail.component";
import PublicModal from "../Components/Modal/PublicModal.Component";
import StepHome from "../Components/Steps/Step.component";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";

const dict = require("../../../dictionary/homedict.json");

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.state = {
      step: [
        {
          id: 0,
          title: this.props.language === 1 ? "สำนักงาน" : "Department office",
          description:
            this.props.language === 1
              ? "เลือกสำนักงานที่ต้องการ"
              : "Select department office.",
        },
      ],
      step_current: 0,
      current: 0,
      stack: [0],
      history: {},
    };
  }

  next(n, history, title, description) {
    this.setState({
      current: this.state.current + n,
      stack: [...this.state.stack, this.state.current + n],
      history: { ...this.state.history, ...history },
      step: [
        ...this.state.step,
        { id: this.state.current + n, title: title, description: description },
      ],
      step_current: this.state.step_current + 1,
    });
  }

  prev() {
    let current = this.state.stack[this.state.stack.length - 2];
    let removeLastIndex = this.state.stack.pop(); // remove last index
    let removeLastIndex_Step = this.state.step.pop();
    this.setState({
      current: current,
      stack: this.state.stack,
      step: this.state.step,
      step_current: this.state.step_current - 1,
    });
  }

  changeLanguage(index) {
    let language;
    if (this.props.language == 1) {
      language = dict[0].wordpackage[index].wordsTH;
    } else {
      language = dict[0].wordpackage[index].wordsEN;
    }
    return language;
  }

  onChange = (current) => {
    this.setState({ current });
  };

  render() {
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 0
    ) {
      return <Redirect to="/login" />;
    }
    const steps = [
      {
        title: "เลือกสำนักงาน",
        content: (
          <Office
            next={this.next}
            dict={this.changeLanguage(0)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "เลือกประเภทงาน",
        content: (
          <ServiceType
            next={this.next}
            data={this.state.history}
            dict={this.changeLanguage(1)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "เลือกประเภทใบอนุญาตขับขี่",
        content: (
          <LicenseType
            next={this.next}
            data={this.state.history}
            dict={this.changeLanguage(2)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "เลือกประเภทเข้ารับบริการ",
        content: (
          <Service
            next={this.next}
            data={this.state.history}
            showType={this.state.history}
            dict={this.changeLanguage(3)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "เลือกประเภทยานพาหนะ",
        content: (
          <VehicleType
            next={this.next}
            showType={this.state.history}
            dict={this.changeLanguage(4)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "กรุณากรอกข้อมูลต่อไปนี้",
        content: (
          <Information
            next={this.next}
            prev={this.prev}
            showType={this.state.history}
            current={this.state.current}
            dict={this.changeLanguage(5)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "เลือกประเภทงาน",
        content: (
          <Typework
            next={this.next}
            data={this.state.history}
            dict={this.changeLanguage(6)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "ปฏิทิน",
        content: (
          <Calendar
            next={this.next}
            data={this.state.history}
            dict={this.changeLanguage(7)}
            Modaldict={this.changeLanguage(8)}
            Modaldict_2={this.changeLanguage(9)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "ยืนยันการจองคิว",
        content: (
          <ConfirmQueue
            next={this.next}
            data={this.state.history}
            prev={this.prev}
            current={this.state.current}
            dict={this.changeLanguage(13)}
            Modaldict={this.changeLanguage(9)}
            Modaldict_2={this.changeLanguage(10)}
            username={this.props.username}
            language={this.props.language}
            username_Admin={""} //เฉพาะ Admin
          />
        ),
      },
      {
        title: "ข้อมูลการจองคิว",
        content: (
          <InfoQueue
            next={this.next}
            data={this.state.history}
            dict={this.changeLanguage(11)}
            language={this.props.language}
          />
        ),
      },
      {
        title: "รายละเอียดเอกสารที่ต้องใช้",
        content: (
          <Detail
            next={this.next}
            prev={this.prev}
            current={this.state.current}
            data={this.state.history}
            dict={this.changeLanguage(11)}
            Modaldict={this.changeLanguage(12)}
            language={this.props.language}
            username={this.props.username}
            role={this.props.role}
          />
        ),
      },
    ];

    return (
      <div className="container-home">
        <Row justify="center" align="center">
          <Col xl={4} lg={4} xs={22} sm={22} className="step-home-bg">
            <Row justify="center" align="center">
              <Col xl={22} xs={22} sm={22} className="step-content-home">
                <StepHome
                  step_current={this.state.step_current}
                  step={this.state.step}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={18} lg={18} sm={22} xs={22} className="home-content-step">
            <Row justify="center" align="center">
              {this.state.current <= 6 ? (
                <img className="img-logo-home-content" src={Logo} />
              ) : null}
            </Row>
            {steps[this.state.current].content}
            <Row justify="center" align="center" className="steps-action">
              {this.state.current > 0 &&
                this.state.current != 5 &&
                this.state.current != 9 &&
                this.state.current != 10 &&
                this.state.current != 8 && (
                  <Button
                    className="back-btn"
                    size="large"
                    onClick={() => this.prev()}
                  >
                    <span>
                      {this.props.language == 1
                        ? dict[0].wordpackage[13].wordsTH[11]
                        : dict[0].wordpackage[13].wordsEN[11]}
                    </span>
                  </Button>
                )}
            </Row>
          </Col>
        </Row>
        <PublicModal language={this.props.language} />
      </div>
    );
  }
}

export default Home;
