/* eslint-disable */
import React, { Component } from "react";
import { Button, Col, Row, Input, Select, Checkbox, Modal } from "antd";
import "./css/InformationForgot.css";
import Logo from "../../../../Image/Logo.png";
import Close from "../../../../Icon/close2.svg";
import LogoClose from "../../../../Icon/close .svg";
import LogoSuccess from "../../../../Icon/checkmark.svg";
import sha1 from "sha1";
import md5 from "md5";
import axios from "axios";

const { Option } = Select;
function getDay(n) {
  let Days = [];
  for (let number = 1; number <= n; number++) {
    if (number > 9) {
      Days.push("" + number);
    } else {
      Days.push("0" + number);
    }
  }
  return Days;
}
function getYears(currentYear) {
  let Years = [];
  for (let i = currentYear - 90; i <= currentYear; i += 1) {
    Years.push(i);
  }
  return Years;
}

class InformationForgotForgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dict and language
      dict: require("../../../../dictionary/menudict.json"),
      language: props.location.state.language,
      // form send api
      birthDay: "",
      passport: "",
      password: "",
      comfirmpassword: "",

      // birthday
      day: "",
      month: "",
      years: "",
      checkboxNotBirth: false,

      // select day month year
      defaultSelectDay: [],
      defaultSelecMonth: [],
      defaultSelectYears: [],
      selectDay: getDay(31),
      selectMonthTH: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      selectMonthEN: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      selectYearsTH: getYears(new Date().getFullYear() + 543),
      selectYearsEN: getYears(new Date().getFullYear()),

      // checkEmpty
      checkEmptyDay: false,
      checkEmptyMonth: false,
      checkEmptyYears: false,
      checkEmptyCheckbox: false,
      checkEmptyPassport: false,
      checkEmptyPassword: false,
      checkEmptyConfirmPassword: false,

      // modal
      isModalVisibleSuccess: false,

      isModalVisibleFail: false,

      // wrong text
      wrongText: "ข้อมูลไม่ถูกต้อง",
    };
  }
  handleChangePrefix(value) {
    this.setState({
      tit_id: value,
      checkEmptyPrefix: false,
    });
  }
  handleChangeDay(value) {
    this.setState({
      day: value,
      defaultSelectDay: value,
      checkEmptyDay: false,
    });
  }
  handleChangeMonth(value) {
    // algorithm check day each month
    if (value == 2) {
      this.setState({
        defaultSelectDay: [],
        day: "",
        selectDay: getDay(28),
      });
    } else if (value == 4 || value == 6 || value == 9 || value == 11) {
      if (this.state.day > "30") {
        this.setState({
          defaultSelectDay: [],
          day: "",
        });
      }
      this.setState({
        selectDay: getDay(30),
      });
    } else {
      this.setState({
        selectDay: getDay(31),
      });
    }

    if (value < 10) {
      var newMonth = value.toString().padStart(2, "0");
    } else {
      var newMonth = value.toString();
    }
    this.setState({
      month: newMonth,
      defaultSelecMonth: newMonth,
      checkEmptyMonth: false,
    });
  }
  handleChangeYears(value) {
    if ((value - 543) % 4 == 0 && this.state.month == "02") {
      this.setState({
        defaultSelectDay: [],
        day: "",
        selectDay: getDay(29),
      });
    }

    if (this.state.language == 1) {
      var yearSent = (value - 543).toString();
    } else {
      var yearSent = value.toString();
    }
    this.setState({
      years: yearSent,
      defaultSelectYears: yearSent,
      checkEmptyYears: false,
    });
  }
  onChangecheckboxNotBirth(e) {
    this.setState({
      checkboxNotBirth: e.target.checked,
      checkEmptyDay: false,
      checkEmptyMonth: false,
      checkEmptyYears: false,
      day: "01",
      month: "01",
      years: "1900",
    });
  }

  checkEmpty() {
    const checkValueNewpassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(
      this.state.password
    );
    if (this.state.checkboxNotBirth == false) {
      if (this.state.day == "") {
        this.setState({
          checkEmptyDay: true,
        });
        this.showModalFail();
      }

      if (this.state.month == "") {
        this.setState({
          checkEmptyMonth: true,
        });
        this.showModalFail();
      }

      if (this.state.years == "") {
        this.setState({
          checkEmptyYears: true,
        });
        this.showModalFail();
      }
    }
    if (this.state.passport == "" || this.state.passport.length < 6) {
      this.setState({
        checkEmptyPassport: true,
        wrongText: "ไม่พบเลขบัตรประชาชนในระบบ / Wrong id or passport number.",
      });
      this.showModalFail();
    }
    if (this.state.password == "") {
      this.setState({
        checkEmptyPassword: true,
      });
      this.showModalFail();
    }

    if (this.state.comfirmpassword == "") {
      this.setState({
        checkEmptyConfirmPassword: true,
      });
      this.showModalFail();
    }

    if (
      this.state.comfirmpassword != this.state.password ||
      checkValueNewpassword == false
    ) {
      this.setState({
        checkEmptyPassword: true,
        checkEmptyConfirmPassword: true,
        wrongText: "รหัสผ่านไม่ถูกต้อง / Password does not match",
      });
      this.showModalFail();
    }

    if (
      this.state.day != "" &&
      this.state.month != "" &&
      this.state.years != "" &&
      this.state.passport != "" &&
      this.state.passport.length >= 6 &&
      this.state.password != "" &&
      this.state.comfirmpassword != "" &&
      this.state.comfirmpassword == this.state.password &&
      checkValueNewpassword == true
    ) {
      this.forgotPassword();
    }
  }

  showModalFail() {
    this.setState({
      isModalVisibleFail: true,
    });
  }
  hideModalFail() {
    this.setState({
      isModalVisibleFail: false,
    });
  }

  showModalSuccess() {
    this.setState({
      isModalVisibleSuccess: true,
    });
  }
  hideModalSuccess() {
    this.setState({
      isModalVisibleSuccess: false,
    });
    this.props.history.push({
      pathname: "/login",
    });
  }

  forgotPassword() {
    try {
      const birthSend =
        this.state.checkboxNotBirth == true
          ? null
          : this.state.years + "-" + this.state.month + "-" + this.state.day;

      const md = md5(this.state.password);
      const hashPassword = sha1(md);

      const data = {
        per_id: this.state.passport,
        password: hashPassword,
        birth_date: birthSend,
      };
      axios
        .post(process.env.REACT_APP_API_DLT1 + "/forgotPass", data, {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          if (response.data == "new password has been set") {
            this.showModalSuccess();
          } else {
            if (response.data === "wrong id") {
              this.setState({
                checkEmptyPassport: true,
                wrongText:
                  "ไม่พบเลขบัตรประชาชนในระบบ / Wrong id or passport number.",
              });
            } else {
              this.setState({
                checkEmptyDay: true,
                checkEmptyMonth: true,
                checkEmptyYears: true,
                wrongText: "วันเกิดไม่ถูกต้อง / Birth date does not match .",
              });
            }
            // this.setState({
            //   checkEmptyDay: true,
            //   checkEmptyMonth: true,
            //   checkEmptyYears: true,
            // });
            this.showModalFail();

            //   setCheckPassport(true);
            //   setCheckDay(true);
            //   setCheckMonth(true);
            //   setCheckYear(true);
            //   setModalSwitchFail(true);
          }
        });
    } catch (error) {}
  }

  render() {
    const dictForgetPassword =
      this.state.dict[this.state.language].Menu[0].WordPackage[2].Words;

    const dictForgetPasswordModalStatus =
      this.state.dict[this.state.language].Menu[0].WordPackage[2].Modal_Status;
    return (
      <div className="content-InformationForgot">
        <Row className="row-InformationForgot-title">
          <Col
            className="col-InformationForgot-title"
            xs={{ span: 20, offset: 2 }}
            sm={{ span: 20, offset: 2 }}
            xl={{ span: 14, offset: 5 }}
          >
            <h1 className="text-InformationForgot-top-title">
              {dictForgetPassword[2]}
            </h1>
            <h2 className="text-InformationForgot-top-title">
              {dictForgetPassword[3]}
            </h2>
            {/* passport*/}
            <Row className="row-InformationForgot-detail">
              <Col
                className="col-InformationForgot-detail"
                offset={1}
                xs={22}
                sm={22}
                xl={22}
              >
                <h2 className="text-InformationForgot-title">
                  {this.state.language == 1
                    ? "บัตรประชาชน"
                    : "Passport/ Non Thai identification card"}
                </h2>
                <Row>
                  <Col
                    className="col-InformationForgot-input select-mar"
                    offset={1}
                    xs={20}
                    sm={22}
                    xl={22}
                  >
                    <Input
                      maxLength={20}
                      className={
                        "InformationForgot-input-passport" +
                        (this.state.checkEmptyPassport ? "show" : "")
                      }
                      placeholder={dictForgetPassword[4]}
                      onChange={(e) =>
                        this.setState({
                          passport: e.target.value,
                          checkEmptyPassport: false,
                        })
                      }
                    />
                  </Col>
                  <h3 className="Asterisk">*</h3>
                </Row>
              </Col>
            </Row>
            {/* birth day */}
            <Row className="row-InformationForgot-detail">
              <Col offset={1} xs={22} sm={22} xl={22}>
                <h2 className="text-InformationForgot-title">
                  {dictForgetPassword[5]}/{dictForgetPassword[6]}/
                  {dictForgetPassword[7]}
                </h2>

                <Row>
                  {/* day */}
                  <Col
                    className="col-InformationForgot-detail select-mar"
                    offset={1}
                    xs={20}
                    sm={6}
                    xl={6}
                  >
                    <Row>
                      <Col
                        xs={23}
                        sm={{ span: 21, offset: 2 }}
                        xl={{ span: 21, offset: 2 }}
                      >
                        <Select
                          disabled={this.state.checkboxNotBirth ? true : false}
                          className={
                            "select-InformationForgot-day" +
                            (this.state.checkEmptyDay ? "show" : "")
                          }
                          value={this.state.defaultSelectDay}
                          placeholder={dictForgetPassword[5]}
                          style={{ width: "100%", borderRadius: 5 }}
                          onChange={(e) => this.handleChangeDay(e)}
                        >
                          {this.state.selectDay.map((day, key) => {
                            return (
                              <Option value={day} key={key}>
                                {day}{" "}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  {/* month */}
                  <Col
                    className="col-InformationForgot-detail select-mar"
                    offset={1}
                    xs={20}
                    sm={6}
                    xl={6}
                  >
                    <Row>
                      <Col xs={23} sm={23} xl={23}>
                        <Select
                          disabled={this.state.checkboxNotBirth ? true : false}
                          className={
                            "select-InformationForgot-month" +
                            (this.state.checkEmptyMonth ? "show" : "")
                          }
                          placeholder={dictForgetPassword[6]}
                          style={{ width: "100%", borderRadius: 5 }}
                          onChange={(e) => this.handleChangeMonth(e)}
                        >
                          {(this.state.language == 1
                            ? this.state.selectMonthTH
                            : this.state.selectMonthEN
                          ).map((month, index) => {
                            return (
                              <Option value={index + 1} key={index}>
                                {month}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  {/* years */}
                  <Col
                    className="col-InformationForgot-detail select-mar"
                    offset={1}
                    xs={20}
                    sm={6}
                    xl={6}
                  >
                    <Row>
                      <Col xs={23} sm={23} xl={23}>
                        <Select
                          disabled={this.state.checkboxNotBirth ? true : false}
                          className={
                            "select-InformationForgot-years" +
                            (this.state.checkEmptyYears ? "show" : "")
                          }
                          placeholder={dictForgetPassword[7]}
                          style={{ width: "100%", borderRadius: 5 }}
                          onChange={(e) => this.handleChangeYears(e)}
                        >
                          {(this.state.language == 1
                            ? this.state.selectYearsTH
                            : this.state.selectYearsEN
                          ).map((Year, index) => {
                            return (
                              <Option value={Year} key={index}>
                                {Year}
                              </Option>
                            );
                          })}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <h3 className="Asterisk">*</h3>
                </Row>
              </Col>
            </Row>
            {/* checkbox */}
            <Row>
              <Col
                className="col-InformationForgot-detail"
                offset={1}
                xs={22}
                sm={22}
                xl={22}
              >
                <Row className="row-InformationForgot-checkbox-birth">
                  <Col
                    className="col-InformationForgot-input select-mar"
                    offset={1}
                    xs={22}
                    sm={22}
                    xl={22}
                  >
                    <Row className="row-InformationForgot-detail-checkbox">
                      <Checkbox
                        className="checkbox-InformationForgot-birth"
                        onChange={(e) => this.onChangecheckboxNotBirth(e)}
                      ></Checkbox>
                      <h4 className="text-InformationForgot-checkbox">
                        {dictForgetPassword[8]}
                      </h4>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* password and confirm */}
            <Row className="row-InformationForgot-detail">
              <Col
                className="col-InformationForgot-detail"
                offset={1}
                xs={22}
                sm={11}
                xl={11}
              >
                <h2 className="text-InformationForgot-title">
                  {this.state.language == 1 ? "รหัสผ่าน" : "Password"}
                </h2>
                <Row>
                  <Col
                    className="col-InformationForgot-input select-mar"
                    offset={1}
                    xs={20}
                    sm={20}
                    xl={20}
                  >
                    <Input.Password
                      className={
                        "ant-input-affix-wrapper-disabled InformationForgot-input-password" +
                        (this.state.checkEmptyPassword ? "show" : "")
                      }
                      placeholder={dictForgetPassword[9]}
                      onChange={(e) =>
                        this.setState({
                          password: e.target.value,
                          checkEmptyPassword: false,
                        })
                      }
                    />
                  </Col>
                  <h3 className="Asterisk"> *</h3>
                </Row>
              </Col>
              <Col
                className="col-InformationForgot-detail"
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 11, offset: 0 }}
                xl={{ span: 11, offset: 0 }}
              >
                <h2 className="text-InformationForgot-title">
                  {dictForgetPassword[10]}
                </h2>
                <Row>
                  <Col
                    className="col-InformationForgot-input select-mar"
                    offset={1}
                    xs={20}
                    sm={20}
                    xl={20}
                  >
                    <Input.Password
                      className={
                        "ant-input-affix-wrapper-disabled InformationForgot-input-confirmpassword" +
                        (this.state.checkEmptyConfirmPassword ? "show" : "")
                      }
                      placeholder={dictForgetPassword[10]}
                      onChange={(e) =>
                        this.setState({
                          comfirmpassword: e.target.value,
                          checkEmptyConfirmPassword: false,
                        })
                      }
                    />
                  </Col>
                  <h3 className="Asterisk"> *</h3>
                </Row>
              </Col>
              {/* text warning */}
              <Col
                className="col-InformationForgot-warning"
                offset={2}
                xs={21}
                sm={21}
                xl={23}
              >
                <h4 className="text-InformationForgot-warning">
                  {dictForgetPassword[11]}
                </h4>
              </Col>
            </Row>
            {/* button */}
            <Row>
              <Col
                className="col-InformationForgot-button select-mar"
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 3, offset: 20 }}
                xl={{ span: 3, offset: 20 }}
              >
                <Button
                  className="button-InformationForgot-confirm"
                  onClick={() => this.checkEmpty()}
                >
                  <p className="p-button-InformationForgot-confirm">
                    {dictForgetPassword[12]}
                  </p>
                </Button>
              </Col>
            </Row>
          </Col>
          {/* modalfail */}
          <Modal
            className="InformationForgot-modalFail"
            visible={this.state.isModalVisibleFail}
            onCancel={() => this.hideModalFail()}
            closeIcon={<img src={Close} />}
            footer={null}
          >
            <Row>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 20, offset: 2 }}
                xl={{ span: 20, offset: 2 }}
                className="col-InformationForgot-modal-logo-fail"
              >
                <img className="LogoClose-InformationForgot" src={LogoClose} />
              </Col>
            </Row>
            <Row>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 20, offset: 2 }}
                xl={{ span: 20, offset: 2 }}
                className="col-InformationForgot-modal-text-fail "
              >
                <h2 className="text-InformationForgot-modal-fail">
                  {this.state.wrongText}
                </h2>
              </Col>
            </Row>
          </Modal>
          {/* modal Success */}
          <Modal
            className="InformationForgot-modalSuccess"
            visible={this.state.isModalVisibleSuccess}
            onCancel={() => this.hideModalSuccess()}
            closeIcon={<img src={Close} />}
            footer={null}
          >
            <Row>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 20, offset: 2 }}
                xl={{ span: 20, offset: 2 }}
                className="col-InformationForgot-modal-logo-success"
              >
                <img
                  className="LogoSuccess-InformationForgot"
                  src={LogoSuccess}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 20, offset: 2 }}
                xl={{ span: 20, offset: 2 }}
                className="col-InformationForgot-modal-text-success"
              >
                <h2 className="text-InformationForgot-modal-success">
                  {dictForgetPasswordModalStatus[1]}
                </h2>
              </Col>
            </Row>
          </Modal>
        </Row>
      </div>
    );
  }
}

export default InformationForgotForgot;
