/* eslint-disable */
import React from "react";
import AuthRouter from "./Screen/Users/Components/Auth/AuthRoute.component";
import "./App.css";
import axios from "axios";
import { withRouter } from "react-router";
import Schema from "async-validator";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    try {
      await axios
        .get(process.env.REACT_APP_API_DLT2 + "/checkSession")
        .then((response) => {
          if (response.data == "nosession") {
            localStorage.removeItem("username");
            localStorage.removeItem("role");
            localStorage.removeItem("lang");
            this.props.history.push({
              pathname: "/login",
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    Schema.warning = function () {};
    return <AuthRouter />;
  }
}

export default withRouter(App);
