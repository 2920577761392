/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Image, Divider, Modal, Button, Row, Col, Input } from "antd";
import Logo from "../../img/logo/LogoDLT.png";
import "./profile.css";
import sha1 from "sha1";
import md5 from "md5";
import axios from "axios";
import Close2 from "../../img/icon/close2.svg";
import Close1 from "../../img/icon/close 1.svg";
import Unlock from "../../img/icon/unlock.svg";
import CheckMark from "../../img/icon/check mark.svg";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dict: require("../../../dictionary/menudict.json"),
      // language: 1 /* props.location.state.language */,
      modalVisibke: false,
      modalcheck: false,
      oldPassword: "",
      newPassword: "",
      subnewPassword: "",
      password: "",
      comfirmpassword: "",
      username: this.props.username,
      language: this.props.language,

      // check empty
      checkOldpassword: false,
      checkNewpassword: false,
      checkSubNewpassword: false,
    };
  }

  _click() {
    this.setState({ modalVisible: false });
  }

  checkinputpass() {
    const checkValueNewpassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(
      this.state.newPassword
    );
    if (this.state.oldPassword == "") {
      this.setState({
        checkOldpassword: true,
        modalcheck: true,
      });
    }
    if (this.state.newPassword == "") {
      this.setState({
        checkNewpassword: true,
        modalcheck: true,
      });
    }
    if (this.state.subnewPassword == "") {
      this.setState({
        checkSubNewpassword: true,
        modalcheck: true,
      });
    }
    if (
      this.state.newPassword != this.state.subnewPassword ||
      checkValueNewpassword == false
    ) {
      this.setState({
        checkNewpassword: true,
        checkSubNewpassword: true,
        modalcheck: true,
      });
    }
    if (
      this.state.oldPassword != "" &&
      this.state.newPassword != "" &&
      this.state.subnewPassword != "" &&
      this.state.newPassword == this.state.subnewPassword &&
      checkValueNewpassword == true
    ) {
      this.changePassword();
    }
  }

  resetinput() {
    this.setState({ oldPassword: "" });
    this.setState({ newPassword: "" });
    this.setState({ subnewPassword: "" });
    this.setState({ modalcheck: false });
  }

  componentDidMount() {
    const data = { username: this.state.username };
    try {
      axios
        .post(process.env.REACT_APP_API_DLT1 + "/getPersonalProfile", data, {})
        .then((response) => {
          this.setState({
            tit_id: response.data.per_id,
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  changePassword() {
    try {
      const md_old = md5(this.state.oldPassword);
      const hasholdPassword = sha1(md_old);

      const md_new = md5(this.state.newPassword);
      const hashnewPassword = sha1(md_new);

      const datapass = {
        username: this.state.username,
        old_pass: hasholdPassword,
        new_pass: hashnewPassword,
      };
      axios
        .post(
          process.env.REACT_APP_API_DLT1 + "/setting/changepassword",
          datapass,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data == "password has been update") {
            this.setState({ modalVisible: true });
          } else {
            this.setState({
              checkOldpassword: true,
              checkNewpassword: true,
              checkSubNewpassword: true,
              modalcheck: true,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 0
    ) {
      return <Redirect to="/login" />;
    }
    const dictSetPassword =
      this.state.dict[this.state.language].Menu[4].WordPackage[2].Words;

    const dictStatusModal =
      this.state.dict[this.state.language].Menu[4].WordPackage[3].Words;
    return (
      <div className="container-profile">
        <Modal
          className="modalprofile"
          footer={null}
          closable={false}
          visible={this.state.modalVisible}
        >
          <div>
            <img
              className="btn-close"
              src={Close2}
              onClick={() => this._click()}
            />
          </div>

          <div className="divbe">
            <div className="iconcheckmark">
              <img src={CheckMark} />
            </div>
            <div className="modalsave">{dictStatusModal[1]}</div>
            <Link to="/dlt-Smartqueue/profile">
              <Button className="btn-be" onClick={() => this._click()}>
                {this.state.language == 1 ? "รับทราบ" : "Accept"}
              </Button>
            </Link>
          </div>
        </Modal>
        <Modal
          className="modalprofile"
          footer={null}
          closable={false}
          visible={this.state.modalcheck}
        >
          <div>
            <img
              className="btn-close"
              src={Close2}
              onClick={() => this.resetinput()}
            />
          </div>

          <div className="divbe">
            <div className="iconcheckmark">
              <img src={Close1} />
            </div>
            <div className="modalsave">{dictStatusModal[0]}</div>
            <div className="secontext">{dictStatusModal[2]}</div>
            <Button className="btn-be" onClick={() => this.resetinput()}>
              {this.state.language == 1 ? "รับทราบ" : "Accept"}
            </Button>
          </div>
        </Modal>

        <Row justify="center" className="row-profile-main">
          <Col xs={20} sm={20} xl={20} className="bg-profile-content">
            <Row justify="center" align="top">
              <Col>
                <Image preview={false} src={Logo} />
              </Col>
            </Row>
            <Row justify="center">
              <div className="titleprofile">
                {this.state.language == 1 ? "ตั้งค่า" : "Setting"}
              </div>
            </Row>
            <Row justify="center">
              <div className="Breadcrumbchangepassword">
                {dictSetPassword[0]}
              </div>
            </Row>

            <div className="containerpassword">
              <Col xs={24} sm={24} xl={24}>
                <Row>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 12, offset: 6 }}
                    lg={{ span: 12, offset: 6 }}
                    xl={{ span: 12, offset: 6 }}
                  >
                    <Row>
                      <Col
                        xs={{ span: 20, offset: 5 }}
                        sm={{ span: 12, offset: 7 }}
                        md={{ span: 13, offset: 6 }}
                        lg={{ span: 12, offset: 6 }}
                        xl={{ span: 12, offset: 5 }}
                      >
                        <h5 className="subtextpass">{dictSetPassword[1]}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ span: 1, offset: 2 }}
                        sm={{ span: 1, offset: 4 }}
                        md={{ span: 1, offset: 4 }}
                        lg={{ span: 1, offset: 4 }}
                        xl={{ span: 1, offset: 4 }}
                      >
                        <img src={Unlock} />
                      </Col>

                      <Col
                        xs={{ span: 19, offset: 1 }}
                        sm={{ span: 15, offset: 2 }}
                        md={{ span: 15, offset: 1 }}
                        lg={{ span: 15, offset: 1 }}
                        xl={{ span: 15, offset: 0 }}
                      >
                        <Input.Password
                          className={
                            "ant-input-affix-wrapper-disabled info-input-pass-old" +
                            (this.state.checkOldpassword ? "show" : "")
                          }
                          placeholder={dictSetPassword[1]}
                          value={this.state.oldPassword}
                          onChange={(e) =>
                            this.setState({
                              oldPassword: e.target.value,
                              checkOldpassword: false,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ span: 20, offset: 5 }}
                        sm={{ span: 13, offset: 7 }}
                        md={{ span: 13, offset: 6 }}
                        lg={{ span: 12, offset: 6 }}
                        xl={{ span: 12, offset: 5 }}
                      >
                        <h5 className="subtextpass">{dictSetPassword[2]}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ span: 1, offset: 2 }}
                        sm={{ span: 1, offset: 4 }}
                        md={{ span: 1, offset: 4 }}
                        lg={{ span: 1, offset: 4 }}
                        xl={{ span: 1, offset: 4 }}
                      >
                        <img src={Unlock} />
                      </Col>
                      <Col
                        xs={{ span: 19, offset: 1 }}
                        sm={{ span: 15, offset: 2 }}
                        md={{ span: 15, offset: 1 }}
                        lg={{ span: 15, offset: 1 }}
                        xl={{ span: 15, offset: 0 }}
                      >
                        <Input.Password
                          className={
                            "ant-input-affix-wrapper-disabled info-input-pass-new" +
                            (this.state.checkNewpassword ? "show" : "")
                          }
                          placeholder={dictSetPassword[2]}
                          value={this.state.newPassword}
                          onChange={(e) =>
                            this.setState({
                              newPassword: e.target.value,
                              checkNewpassword: false,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ span: 20, offset: 5 }}
                        sm={{ span: 17, offset: 7 }}
                        md={{ span: 13, offset: 6 }}
                        lg={{ span: 12, offset: 6 }}
                        xl={{ span: 12, offset: 5 }}
                      >
                        <h5 className="subtextpass">{dictSetPassword[3]}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ span: 1, offset: 2 }}
                        sm={{ span: 1, offset: 4 }}
                        md={{ span: 1, offset: 4 }}
                        lg={{ span: 1, offset: 4 }}
                        xl={{ span: 1, offset: 4 }}
                      >
                        <img src={Unlock} />
                      </Col>
                      <Col
                        xs={{ span: 19, offset: 1 }}
                        sm={{ span: 15, offset: 2 }}
                        md={{ span: 15, offset: 1 }}
                        lg={{ span: 15, offset: 1 }}
                        xl={{ span: 15, offset: 0 }}
                      >
                        <Input.Password
                          className={
                            "ant-input-affix-wrapper-disabled info-input-pass-sub" +
                            (this.state.checkSubNewpassword ? "show" : "")
                          }
                          placeholder={dictSetPassword[3]}
                          value={this.state.subnewPassword}
                          onChange={(e) =>
                            this.setState({
                              subnewPassword: e.target.value,
                              checkSubNewpassword: false,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ span: 20, offset: 5 }}
                        sm={{ span: 17, offset: 6 }}
                        md={{ span: 16, offset: 6 }}
                        lg={{ span: 15, offset: 6 }}
                        xl={{ span: 15, offset: 5 }}
                      >
                        <h5 className="subtextpass">
                          {this.state.language == 1
                            ? "*รหัสผ่านไม่ต่ำกว่า 6 ตัว ประกอบไปด้วยตัวเลขและตัวอักษร a-z อย่างน้อย 1 ตัว (ได้ทั้งตัวพิมพ์เล็กและตัวพิมพ์ใหญ่)"
                            : "Password must contain at least 6 characters, including at least one digit and one 1 letter (either uppercase or lowercase)"}
                        </h5>
                      </Col>
                    </Row>
                    <div className="containerbtnpass">
                      <Row>
                        <Col
                          xs={{ span: 7, offset: 3 }}
                          sm={{ span: 7, offset: 4 }}
                          md={{ span: 5, offset: 5 }}
                          lg={{ span: 5, offset: 6 }}
                          xl={{ span: 7, offset: 4 }}
                        >
                          <Link to="/dlt-Smartqueue/profile">
                            <Button className="btnupdatepass">
                              {this.state.language == 1 ? "ยกเลิก" : "Cancel"}
                            </Button>
                          </Link>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 2 }}
                          sm={{ span: 11, offset: 2 }}
                          md={{ span: 9, offset: 3 }}
                          lg={{ span: 7, offset: 4 }}
                          xl={{ span: 7, offset: 2 }}
                        >
                          <Button
                            className="btnupdatepass"
                            onClick={() => this.checkinputpass()}
                          >
                            {this.state.language == 1
                              ? "อัพเดตรหัสผ่าน"
                              : "Update password"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ChangePassword;
