import React from "react";
import { Route, Redirect } from "react-router-dom";
const ProtectedRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          localStorage.getItem("role") !== 1 &&
          localStorage.getItem("username") === null
        ) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          );
        } else {
          return children;
        }
      }}
    />
  );
};
export default ProtectedRoute;
