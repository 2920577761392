/* eslint-disable */
import React from "react";
import { Input, Button, Table, Row, Col, Tag, Select } from "antd";
import "../../css/Admin/admin.css";
import Logo from "../../Icon/logo-web.svg";
import sha1 from "sha1";
import md5 from "md5";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
// import moment from "moment";
import DetailQueue from "./Components/Modal/DetailQueue.component";
import DetailQueuePrint from "./Components/Modal/DetailQueuePrint.component";
import CryptoJS from "crypto-js";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.showModalQueue = this.showModalQueue.bind(this);
    this.handleOnClickUpdateTable = this.handleOnClickUpdateTable.bind(this);
    this.state = {
      id_card: null,
      fname: "",
      lname: "",
      phone: null,
      dataSource: [],
      username: this.props.username,
      checkID: true,
      resetPass: true,
      newUser: false,
      visible: false,
      checkFname: false,
      checkLname: false,
      checkLang: false,
      data_regis: false,
      lang: null,
      warning_id: false,
      staff_username: this.props.username,
      dataToPrint: "",
      boo_codeToPrint: "",
    };
  }

  async handleOnClickUpdateTable() {
    try {
      let data = {
        username: this.props.username,
      };
      const booking = await axios.get(
        process.env.REACT_APP_API_DLT1 + "/admin/getBooking",
        {
          mode: "cors",
          params: data,
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const bookingJson = await booking.data;
      this.setState({
        dataSource: bookingJson,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidMount() {
    try {
      let data = {
        username: this.props.username,
      };
      const booking = await axios.get(
        process.env.REACT_APP_API_DLT1 + "/admin/getBooking",
        {
          mode: "cors",
          params: data,
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const bookingJson = await booking.data;
      this.setState({
        dataSource: bookingJson,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getBooking(username) {
    try {
      let data = {
        username: username,
      };
      const booking = await axios.get(
        process.env.REACT_APP_API_DLT1 + "/admin/getBooking",
        {
          mode: "cors",
          params: data,
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const bookingJson = await booking.data;
      this.setState({
        dataSource: bookingJson,
      });
    } catch (error) {
      console.log(error);
    }
  }

  register() {
    try {
      // hashPassword
      const hashPassword = sha1(md5(this.state.id_card));

      const data = {
        tit_id: 4,
        fname: this.state.fname,
        lname: this.state.lname,
        per_id: this.state.id_card,
        birth_date: null,
        phone: this.state.phone,
        email: null,
        line_id: null,
        password: hashPassword,
        lang: this.state.lang,
      };
      axios
        .post(process.env.REACT_APP_API_DLT1 + "/user/register", data, {
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          if (response.data == "Already Registed!!") {
            // this.setState({
            //   data_regis: false,
            // });
          } else if (response.data == "insertComplete") {
            axios
              .post(
                process.env.REACT_APP_API_DLT1 + "/admin/userCheck",
                {
                  per_id: this.state.id_card,
                },
                {
                  mode: "cors",
                  headers: {
                    "Content-type": "application/json",
                  },
                }
              )
              .then((res) => {
                this.props.callbackUser(
                  res.data.username,
                  this.state.id_card,
                  res.data.sets.per_language === "TH" ? 1 : 2
                );
                this.props.history.push({
                  pathname: "/admin/home",
                });
              });
          }
          // else {
          // this.setState({
          //   data_regis: false,
          // });
          // }
        });
    } catch (error) {
      console.log(error);
    }
  }

  async checkUser(value) {
    try {
      let data = {
        per_id: value,
      };
      const username = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/admin/userCheck",
        data,
        {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const usernameJson = await username.data;
      if (usernameJson === "this id has not registed yet") {
        this.setState({
          checkID: false,
          newUser: true,
          warning_id: true,
          fname: "",
          lname: "",
          phone: "",
        });
      } else if (usernameJson != "this id has not registed yet") {
        this.setState({
          checkID: false,
          resetPass: false,
          newUser: false,
          username: usernameJson.username,
          fname: usernameJson.sets.per_fname,
          lname: usernameJson.sets.per_lname,
          phone: usernameJson.sets.per_phone,
          lang: usernameJson.sets.per_language === "EN" ? 2 : 1,
        });
        this.getBooking(usernameJson.username);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // checkID(id) {
  //   if (id.length != 13) {
  //     return false;
  //   }
  //   for (var i = 0, sum = 0; i < 12; i++) {
  //     sum += parseFloat(id.charAt(i)) * (13 - i);
  //   }
  //   if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) {
  //     return false;
  //   }
  //   return true;
  // }

  async resetPassword() {
    try {
      let data = {
        username: this.state.username,
        new_pass: sha1(md5(this.state.id_card)),
      };
      const resetPass = await axios.post(
        process.env.REACT_APP_API_DLT1 + "/admin/resetPassword",
        data,
        {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const resetPassJson = await resetPass.data;
      if (resetPassJson === "OK") {
        toast.configure();
        toast.success("เเก้ไขรหัสผ่านเรียบร้อย", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async checkDataInfo() {
    if (this.state.newUser === true) {
      if (this.state.fname === "") {
        this.setState({
          checkFname: true,
        });
      }
      if (this.state.fname === "") {
        this.setState({
          checkLname: true,
        });
      }

      if (this.state.lang === null) {
        this.setState({
          checkLang: true,
        });
      }

      if (
        this.state.checkFname === false &&
        this.state.checkLname === false &&
        this.state.checkLang === false
      ) {
        this.register();
      }
    } else {
      this.props.callbackUser(
        this.state.username,
        this.state.id_card,
        this.state.lang
      );
      this.props.history.push({
        pathname: "/admin/home",
      });
    }
  }

  showModalQueue(type) {
    this.setState({
      visible: type,
    });
  }

  // function go to register when find id and passport not found
  goToRegister = () => {
    try {
      axios
        .get(process.env.REACT_APP_API_DLT2 + "/clearCookie", {
          mode: "cors",
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.data === "OK") {
            localStorage.removeItem("username");
            localStorage.removeItem("role");
            localStorage.removeItem("lang");
            this.props.history.push({
              pathname: "/register",
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 1
    ) {
      return <Redirect to="/login" />;
    }
    const columns = [
      {
        title: "รหัสการจอง",
        dataIndex: "boo_code",
        key: "boo_code",
        responsive: ["sm", "xs", "xl"],
      },
      {
        title: "วันที่จอง",
        dataIndex: "boo_datebook",
        key: "boo_datebook",
        defaultSortOrder: "descend",
        sorter: (a, b) => new Date(a.boo_datebook) - new Date(b.boo_datebook),
        width: 150,
        responsive: ["sm", "xs", "xl"],
      },
      {
        title: "รอบที่จอง",
        dataIndex: "boo_round",
        key: "boo_round",
        width: 150,
        responsive: ["sm", "xs", "xl"],
      },
      {
        title: "ประเภทงาน",
        dataIndex: "tyw_name",
        key: "tyw_name",
        responsive: ["sm", "xs", "xl"],
      },
      {
        title: "สถานที่รับบริการ",
        dataIndex: "sit_name",
        key: "sit_name",
        responsive: ["sm", "xs", "xl"],
      },
      {
        title: "สถานะ",
        dataIndex: "status_name",
        key: "status_name",
        render: (text, record) => {
          if (record.status_name.includes("ยกเลิก")) {
            return <Tag color={"red"}>{record.status_name}</Tag>;
          } else {
            return (
              <div key={record.boo_code}>
                <DetailQueuePrint
                  data={record}
                  handleUpdate={this.handleOnClickUpdateTable}
                  username={this.state.username}
                  id={this.state.id_card}
                  fname={this.state.fname}
                  lname={this.state.lname}
                  username_Admin={this.props.username_Admin}
                />
                <DetailQueue
                  data={record}
                  handleUpdate={this.handleOnClickUpdateTable}
                  username={this.state.username}
                  id={this.state.id_card}
                  fname={this.state.fname}
                  lname={this.state.lname}
                  username_Admin={this.props.username_Admin}
                />
              </div>
            );
          }
        },
        responsive: ["sm", "xs", "xl"],
      },
    ];
    return (
      <div className="container-admin">
        <Row justify="center" align="center" className="section-one-CardID">
          <Col
            xl={{ span: 20 }}
            sm={{ span: 22 }}
            xs={{ span: 22 }}
            className="col-content-CardID"
          >
            <div className="content-admin-layout">
              <div className="position-addmin-center ">
                <img
                  // className="position-addmin-center"
                  src={Logo}
                  width="100"
                  height="100"
                />
              </div>

              <h2 className="title-admin">จองให้ประชาชน</h2>
              <h3 className="title-admin">
                เข้าใช้โดย เจ้าหน้าที่จองแทนประชาชน
              </h3>

              <Row justify="center" align="middle" className="padding-top-id">
                <Col
                  xl={{ span: 10 }}
                  sm={{ span: 10 }}
                  xs={{ span: 22 }}
                  // className="asdasdas"
                >
                  <h4 className="title-admin">ประเภทบัตร</h4>
                  <Select
                    placeholder="กรุณาเลือกประเภทบัตร"
                    bordered={false}
                    className={
                      this.state.checkLang
                        ? "default-id_card-failed"
                        : "default-id_card"
                    }
                    style={{ width: "100%" }}
                    dropdownStyle={{ borderRadius: 20 }}
                    onChange={(value, index) => {
                      this.setState({
                        lang: value,
                        checkLang: false,
                      });
                    }}
                    value={this.state.lang}
                  >
                    <Option key="1" className="option" value={1}>
                      คนไทย
                    </Option>
                    <Option key="2" className="option" value={2}>
                      ชาวต่างชาติ
                    </Option>
                  </Select>
                </Col>
              </Row>

              {/* ID Card */}
              <div className="content-admin padding-top-id">
                <div className="column-admin">
                  <div className="flex-start-admin">
                    <h4 className="title-admin">
                      หมายเลขบัตรประชาชน/Passport/Non Thai Identification Card
                    </h4>
                  </div>
                  <Input
                    maxLength={13}
                    size="large"
                    className="info-input-admin ID_card neumorphic-admin neumorphic--pressed-admin"
                    placeholder="เลขบัตรประชาชน"
                    onChange={(e) => {
                      this.setState({
                        id_card: e.target.value,
                        checkID: true,
                        resetPass: true,
                        warning_id: false,
                        fname: "",
                        lname: "",
                        phone: "",
                      });
                    }}
                    value={this.state.id_card}
                  />
                  <div className="flex-start-admin">
                    {this.state.warning_id ? (
                      <h4 className="title-admin red-noti">
                        *ไม่พบเลขบัตรประชาชนนี้ในระบบ กรุณาลงทะเบียน
                      </h4>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="content-admin section-2">
                <div className="rows-admin">
                  <div className="column-admin-2 space">
                    <div className="flex-start-admin">
                      <h4 className="title-admin">ชื่อ</h4>
                    </div>
                    <Input
                      size="large"
                      className={
                        this.state.checkFname
                          ? "info-input-admin admin-fname neumorphic-admin neumorphic--pressed-admin checkEmpty-name"
                          : "info-input-admin admin-fname neumorphic-admin neumorphic--pressed-admin"
                      }
                      placeholder="ชื่อ"
                      disabled
                      onChange={(e) => {
                        this.setState({
                          fname: e.target.value,
                          checkFname: false,
                        });
                      }}
                      value={this.state.fname}
                    />
                  </div>
                  <div className="column-admin-2 space">
                    <div className="flex-start-admin">
                      <h4 className="title-admin">นามสกุล</h4>
                    </div>
                    <Input
                      size="large"
                      disabled
                      className={
                        this.state.checkLname
                          ? "info-input-admin admin-lname neumorphic-admin neumorphic--pressed-admin checkEmpty-name"
                          : "info-input-admin admin-lname neumorphic-admin neumorphic--pressed-admin"
                      }
                      placeholder="นามสกุล"
                      onChange={(e) => {
                        this.setState({
                          lname: e.target.value,
                          checkLname: false,
                        });
                      }}
                      value={this.state.lname}
                    />
                  </div>
                  <div className="column-admin-2">
                    <div className="flex-start-admin">
                      <h4 className="title-admin">หมายเลขโทรศัพท์</h4>
                    </div>
                    <Input
                      size="large"
                      disabled
                      className="info-input-admin admin-idname neumorphic-admin neumorphic--pressed-admin"
                      placeholder="หมายเลขโทรศัพท์"
                      onChange={(e) => {
                        this.setState({
                          phone: e.target.value,
                        });
                      }}
                      value={this.state.phone}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="steps-action section-2 position-addmin-center">
              <Button
                // disabled={this.state.resetPass}
                className="back-btn admin-btn-back-padding"
                size="large"
                onClick={() => this.checkUser(this.state.id_card)}
              >
                <span>เช็คข้อมูล</span>
              </Button>
              {/* <Link to="/admin/home"> */}
              <Button
                disabled={this.state.checkID}
                className="back-btn-admin"
                size="large"
                onClick={() => this.checkDataInfo()}
              >
                <span>จองคิว</span>
              </Button>
              {/* </Link> */}
              <Button
                disabled={this.state.resetPass}
                className="back-btn admin-btn-back-padding"
                size="large"
                onClick={() => this.resetPassword()}
              >
                <span>รีเซ็ตรหัสผ่าน</span>
              </Button>
              <Button
                disabled={!this.state.warning_id}
                className="back-btn admin-btn-back-padding"
                size="large"
                onClick={() => this.goToRegister()}
              >
                <span>ลงทะเบียน</span>
              </Button>
            </div>
          </Col>
        </Row>

        <div className="section-3">
          <Row justify="center">
            <Col
              xl={{ span: 20 }}
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              className="col-table-admin"
            >
              <Row justify="center">
                <Col
                  xl={{ span: 20 }}
                  xs={{ span: 22 }}
                  sm={{ span: 22 }}
                  // className="col-table-admin-data"
                >
                  <Table
                    scroll={{ x: true }}
                    // rowSelection={(selectedRowKeys) => console.log(selectedRowKeys)}
                    rowKey={this.state.dataSource.key}
                    dataSource={this.state.dataSource}
                    loading={this.state.loading}
                    columns={columns}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(Admin);
