/* eslint-disable */
import React, { Component } from "react";
import { Divider, Button, Row, Col } from "antd";
import "./css/HistoryCancel.css";
import axios from "axios";
import QRCode from "qrcode.react";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
export class HistoryCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      username: this.props.username,
      language: this.props.language,
      loading: false,
    };
  }
  componentDidMount() {
    try {
      axios
        .get(process.env.REACT_APP_API_DLT1 + "/getBooking", {
          params: {
            username: this.state.username,
            boo_status: 0,
            lang: this.state.language,
          },
        })
        .then((response) => {
          if (response.data != "can't find booking of this username") {
            this.setState({
              data: response.data,
              loading: true,
            });
          } else {
            this.setState({
              loading: true,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  // convert time th to en
  setBooround = (boo_round) => {
    const roundAll = boo_round.split(" ");
    const roundStart = roundAll[0];
    const roundEnd = roundAll[2];
    if (parseInt(roundStart.slice(0, 2)) > 12) {
      const roundStartnew = parseInt(roundStart.slice(0, 2)) - 12;
      const roundEndnew = parseInt(roundEnd.slice(0, 2)) - 12;
      const last = "PM";
      return `${roundStartnew.toString() + roundStart.slice(2)} - ${
        roundEndnew.toString() + roundEnd.slice(2)
      } ${last}`;
    } else {
      const last = "AM";
      return `${roundStart} - ${roundEnd} ${last}`;
    }
  };
  render() {
    if (
      CryptoJS.AES.decrypt(
        localStorage.getItem("role"),
        localStorage.getItem("username")
      ).toString(CryptoJS.enc.Utf8) != 0
    ) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="content-historyCancel">
        <Row
          justify="center"
          align="middle"
          className="container-historyCancel-main"
        >
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            xl={{ span: 20 }}
            className="container-historyCancel-main-bg"
          >
            {this.state.loading ? (
              <div>
                {this.state.data.map((dataProgress, index) => {
                  return (
                    <div
                      key={index}
                      className="div-cancel-q"
                      // style={{ background: "rgba(255, 255, 255, 0.6)", zIndex: 12 }}
                    >
                      <Row
                        justify="center"
                        className="row-header-historyCancel"
                      >
                        {/* header */}
                        <Col
                          className="col-header-historyCancel"
                          xs={{ span: 22 }}
                          sm={{ span: 22 }}
                          xl={{ span: 16 }}
                        >
                          <Col offset={1}>
                            <h2 className="title-header-historyCancel">
                              {this.state.language == 1
                                ? "ข้อมูลการจองคิวที่ยกเลิก"
                                : "Cancel Booking "}
                            </h2>
                          </Col>
                        </Col>
                        {/* content */}
                        <Col
                          className="col-content-historyCancel"
                          xs={{ span: 22 }}
                          sm={{ span: 22 }}
                          xl={{ span: 16 }}
                        >
                          <Row>
                            {/* detail */}
                            <Col
                              className="col-content-historyCancel-detail"
                              xs={{ span: 22, offset: 1 }}
                              sm={{ span: 16, offset: 1 }}
                              xl={{ span: 17, offset: 1 }}
                            >
                              {/* name and passport */}
                              <Row>
                                <Col
                                  xs={{ span: 20 }}
                                  sm={{ span: 11 }}
                                  xl={{ span: 11 }}
                                  className="col-content-historyCancel-detail-name"
                                >
                                  <p className="p-topic">
                                    {this.state.language == 1
                                      ? "ชื่อ - นามสกุล"
                                      : "Name"}
                                  </p>
                                  <p className="p-value">
                                    {dataProgress.per_fname}{" "}
                                    {dataProgress.per_lname}
                                  </p>
                                </Col>
                                <Col
                                  xs={{ span: 20 }}
                                  sm={{ span: 11 }}
                                  xl={{ span: 11 }}
                                  className="col-content-historyCancel-detail-passport"
                                >
                                  <p className="p-topic">
                                    {this.state.language == 1
                                      ? "เลขประจำตัวประชาชน"
                                      : "ID No."}
                                  </p>
                                  <p className="p-value">
                                    {dataProgress.per_id}
                                  </p>
                                </Col>
                              </Row>
                              <Divider
                                style={{ marginTop: 0, marginBottom: 14 }}
                              />
                              {/* date and time */}
                              <Row>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 12 }}
                                  xl={{ span: 12 }}
                                  className="col-content-historyCancel-detail-date"
                                >
                                  <p className="p-topic">
                                    {this.state.language == 1
                                      ? "วันที่"
                                      : "Booking date"}
                                  </p>
                                  <p className="p-value">
                                    {dataProgress.dateText}
                                  </p>
                                </Col>
                                <Col
                                  xs={{ span: 24 }}
                                  sm={{ span: 12 }}
                                  xl={{ span: 12 }}
                                  className="col-content-historyCancel-detail-time"
                                >
                                  <p className="p-topic">
                                    {this.state.language == 1
                                      ? "เวลา"
                                      : "Round"}
                                  </p>
                                  <p className="p-value">
                                    {this.state.language == 1
                                      ? dataProgress.boo_round
                                      : this.setBooround(
                                          dataProgress.boo_round
                                        )}
                                  </p>
                                </Col>
                              </Row>
                              {/* tyw */}
                              <Row>
                                <Col
                                  xs={{ span: 20 }}
                                  sm={{ span: 22 }}
                                  xl={{ span: 22 }}
                                  className="col-content-historyCancel-detail-tyw"
                                >
                                  {/* <Row> */}
                                  <p className="p-topic">
                                    {this.state.language == 1
                                      ? "ประเภทงานบริการ"
                                      : "Type"}
                                  </p>
                                  <p className="p-value">
                                    {dataProgress.tyw_name}
                                  </p>
                                  {/* </Row> */}
                                </Col>
                              </Row>
                              {/* site */}
                              <Row>
                                <Col
                                  xs={{ span: 20 }}
                                  sm={{ span: 22 }}
                                  xl={{ span: 22 }}
                                  className="col-content-historyCancel-detail-site"
                                >
                                  <p className="p-topic">
                                    {this.state.language == 1
                                      ? "สำนักงาน"
                                      : "Office"}
                                  </p>
                                  <p className="p-value">
                                    {dataProgress.sit_name}
                                  </p>
                                </Col>
                              </Row>
                              {/* หมดอายุเกิน 1 ปี */}
                              {dataProgress.threeyear ? (
                                <Row>
                                  <Col
                                    xs={{ span: 20 }}
                                    sm={{ span: 22 }}
                                    xl={{ span: 22 }}
                                    className="col-content-history-detail-site"
                                  >
                                    <p className="p-topic">
                                      {this.state.language == 1
                                        ? "หมายเหตุ"
                                        : "Warning"}
                                    </p>
                                    <p className="p-value">
                                      {this.state.language == 1
                                        ? "ใบอนุญาตขับรถของท่านสิ้นอายุเกิน 3 ปี"
                                        : "your driver's license has expired more than 3 year."}
                                    </p>
                                  </Col>
                                </Row>
                              ) : dataProgress.one_year ? (
                                <Row>
                                  <Col
                                    xs={{ span: 20 }}
                                    sm={{ span: 22 }}
                                    xl={{ span: 22 }}
                                    className="col-content-history-detail-site"
                                  >
                                    <p className="p-topic">
                                      {this.state.language == 1
                                        ? "หมายเหตุ"
                                        : "Warning"}
                                    </p>
                                    <p className="p-value">
                                      {this.state.language == 1
                                        ? "ใบอนุญาตขับรถของท่านสิ้นอายุเกิน 1 ปี"
                                        : "your driver's license has expired more than 1 year."}
                                    </p>
                                  </Col>
                                </Row>
                              ) : null}
                            </Col>
                            {/* QRcode */}
                            <Col
                              className="col-content-historyCancel-qrcode"
                              xs={{ span: 23 }}
                              sm={{ span: 6 }}
                              xl={{ span: 5 }}
                            >
                              <Row>
                                <Col
                                  xs={{ span: 20, offset: 2 }}
                                  sm={{ span: 20, offset: 2 }}
                                  xl={{ span: 20, offset: 2 }}
                                  className="col-content-historyCancel-detail-qrcode"
                                >
                                  <p className="p-topic">
                                    {this.state.language == 1
                                      ? "สแกนข้อมูลการจองคิว"
                                      : "Scan booking information.."}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  xs={{ span: 20, offset: 2 }}
                                  sm={{ span: 22, offset: 1 }}
                                  xl={{ span: 22, offset: 1 }}
                                  className="col-content-historyCancel-detail-qrcode-img"
                                >
                                  <QRCode
                                    className="QRcode"
                                    value={dataProgress.ticketurl}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  xs={{ span: 20, offset: 2 }}
                                  sm={{ span: 22, offset: 1 }}
                                  xl={{ span: 22, offset: 1 }}
                                  className="col-content-historyCancel-detail-qrcode"
                                >
                                  <p className="p-topic">
                                    {dataProgress.boo_code}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  xs={{ span: 20, offset: 2 }}
                                  sm={{ span: 22, offset: 1 }}
                                  xl={{ span: 22, offset: 1 }}
                                  className="col-content-historyCancel-detail-qrcode"
                                >
                                  <p className="p-topic">QRCode</p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="row-statause-historyCancel">
                        <Col
                          className="col-status-historyCancel"
                          xs={{ span: 16, offset: 4 }}
                          sm={{ span: 16, offset: 4 }}
                          xl={{ span: 16, offset: 4 }}
                        >
                          <h4 className="text-status-historyCancel">
                            {dataProgress.boo_status_name}
                          </h4>
                        </Col>
                        <Divider
                        // style={{ marginTop: 0, marginBottom: 14 }}
                        />
                      </Row>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="size-loadding-history-calcel">
                <ReactLoading type="spinningBubbles" color={"#2f0465"} />
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default HistoryCancel;
