/* eslint-disable */
import React from "react";
import { Button, Col, Row } from "antd";
import Logo from "../../../../Image/Logo.png";
import "./css/Register.css";
import { Link } from "react-router-dom";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = { language: "" };
  }

  render() {
    return (
      <div className="content-register">
        <Row className="row-Logo-register" justify="center" align="top">
          <Col className="col-Logo-register" xs={20} sm={8} xl={6}>
            <img className="logo-register-page" src={Logo} />
          </Col>
        </Row>
        <Row className="row-Content-register" justify="center" align="top">
          <Col className="col-Content-register" xs={22} sm={10} xl={8}>
            <p className="top-text-doc">กรุณาเลือกประเภทผู้ใช้งาน</p>
            <p className="sub-text-doc">Please select a user type.</p>
            <Col className="col-button-doc" offset={1} xs={22} sm={22} xl={22}>
              <Link
                to={{
                  pathname: "/information",
                  state: { language: 1, typeUser: 1 },
                }}
              >
                <Button className="button-doc">
                  <p className="p-button-doc">คนไทย (For Thai)</p>
                </Button>
              </Link>
            </Col>
            <Col className="col-button-doc" offset={1} xs={22} sm={22} xl={22}>
              <Link to="/DocumentaryForeigner">
                <Button className="button-doc">
                  <p className="p-button-doc">ชาวต่างชาติ (For Foreigner)</p>
                </Button>
              </Link>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Register;
